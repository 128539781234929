import * as types from '../types'

const initialState = {
  keywords: [],
  keyword: {},
  isLoading: false,
  deleted: false,
  error: null
}

export const keywordReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_KEYWORDS:
      return {
        ...state,
        isLoading: true,
        deleted: false,
        error: action.error || null,
      }
    case types.GET_KEYWORDS_SUCCESS:
      return {
        ...state,
        keywords: action.payload,
        isLoading: false,
        deleted: false,
        error: action.error || null,
      }
    case types.DELETE_KEYWORD:
      return {
        ...state,
        isLoading: true,
        deleted: action.deleted,
        error: action.error || null,
      }
    default:
      return state
  }
}