import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  PageHeader, Layout, Form, Input, Button,
  notification, Alert, Upload, Card,
  message, Col, Row, Tabs
} from 'antd';
import {
  CheckOutlined, LockOutlined, InboxOutlined, BarcodeOutlined
} from '@ant-design/icons';

import useHttp from '../../../hooks/http';
import Footer from '../../../components/Footer/Footer';

const { Content } = Layout;
const { Dragger } = Upload;
const { TabPane } = Tabs;

const Banner = () => {

  const { isLoading, error, data, sendRequest } = useHttp();

  const match = useRouteMatch('/banners/:id');
  const history = useHistory();
  const [id] = useState(match.params.id);
  const [validationErrors, setValidationErrors] = useState('');
  const [legend, setLegend] = useState('');
  const [mainImage, setMainImage] = useState('');
  const [visibleDelete, setVisibleDelete] = useState(false);

  const propsUploadImage = {
    name: 'image',
    action: `${process.env.REACT_APP_API_URL}/image`,
    multiple: false,
    method: 'PUT',
    onChange(info) {
      if (info.file.status === 'done') {
        message.success(`${info.file.name} carregado com sucesso!`);

        setMainImage(info.fileList[info.fileList.length - 1].response.file);
        console.log(0, info.fileList[info.fileList.length - 1].response.file);
        let uploadedFiles = info.fileList[info.fileList.length - 1].response.file;
        console.log('Uploaded Files', uploadedFiles);
        const graphqlQuery = {
          query: `
            mutation StorageBannerImage ($bannerId: ID!, $originalFile: String!){
              storageBannerImage (id: $bannerId, 
                storageBannerImageInput:{
                  originalFile: $originalFile
                }
              )
              {
                image
              }
            }
          `,
          variables: {
            bannerId: id,
            originalFile: info.fileList[info.fileList.length - 1].response.file,
          }
        };
        sendRequest(
          graphqlQuery,
          'UPDATE_IMAGE',
        );
      }
      else if (info.file.status === 'error') {
        message.error(`Erro ao carregar arquivo ${info.file.name}.`);
      }
    },
  };





  const formRef = useRef();

  const handleSubmit = values => {
    setValidationErrors('');
    if (id !== 'new') {
      // UPDATE
      const graphqlQuery = {
        query: `
        mutation UpdateBanner ($bannerId: ID!, $legend: String!, $url: String!){
          updateBanner(id: $bannerId,
            bannerInput:{
              legend: $legend,
              url: $url
            }
          )
          {
            id
            legend
            url
          }
        }
        `,
        variables: {
          bannerId: id,
          legend: values.legend,
          url: values.url
        }
      };
      sendRequest(
        graphqlQuery,
        'UPDATE_BANNER',
      );
    }
    else {
      const graphqlQuery = {
        query: `
          mutation createBanner (
            $legend: String!, $url: String!){
            createBanner(
              bannerInput:{
                legend: $legend,
                url: $url
              }
            )
            {
              id
              legend
              url
            }
          }
        `,
        variables: {
          bannerId: id,
          legend: values.legend,
          url: values.url,
        }
      };
      sendRequest(
        graphqlQuery,
        'CREATE_BANNER',
      );
    }
  };

  const onFillForm = args => {
    formRef.current.setFieldsValue({
      legend: args.legend,
      url: args.url,
    });
  };

  const getBanner = useCallback(() => {
    const graphqlQuery = {
      query: `
        query Banner ($bannerId: ID!){
          banner(id: $bannerId){
            id
            legend
            image
            url
          }
        }
      `,
      variables: {
        bannerId: id
      }
    };

    sendRequest(
      graphqlQuery,
      'GET_BANNER',
    );
  }, [id, sendRequest]);

  useEffect(() => {
    if (id !== 'new') {
      getBanner();
    }
    getBanner();

  }, [id, getBanner, sendRequest]);

  useEffect(() => {
    if (data) {
      if (data.banner) {
        setLegend(data.banner.legend);
        const args = [];
        args.legend = data.banner.legend;
        args.url = data.banner.url;
        setMainImage(data.banner.image);
        onFillForm(args);
      }
      if (data.updateBanner) {
        notification.open({
          message: 'Registro atualizado com sucesso',
          icon: <CheckOutlined style={{ color: '#008000' }} />,
          duration: 2
        });
        history.replace('/banners/');
      }
      if (data.createBanner) {
        notification.open({
          message: 'Registro inserido com sucesso',
          icon: <CheckOutlined style={{ color: '#008000' }} />,
          duration: 2
        });
        history.replace('/banners/');
      }
      if (data.storageBannerImage) {
        getBanner();
        notification.open({
          message: `Imagem atualizada com sucesso!`,
          icon: <CheckOutlined style={{ color: '#008000' }} />,
          duration: 4
        });
      }
      if (data.storageBannerImages) {
        let status = data.storageBannerImages.status;
        if (status.includes('file(s) successfully uploaded!')) {
          status = status.replace('file(s) successfully uploaded!', 'arquivo(s) carregado(s) com sucesso!');
          getBanner();
        }
        notification.open({
          message: `${status}`,
          icon: <CheckOutlined style={{ color: '#008000' }} />,
          duration: 4
        });
      }
      if (data.deleteBannerImage) {
        getBanner();
        setVisibleDelete(!visibleDelete);
        notification.open({
          message: `Imagem excluída com sucesso!`,
          icon: <CheckOutlined style={{ color: '#008000' }} />,
          duration: 4
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, getBanner, history]);

  useEffect(() => {
    if (error) {
      if (error[0].status === 401) {
        history.replace('/');
        localStorage.clear();
        notification.open({
          message: 'Não Autorizado!',
          description:
            'Você não tem autorização para acessar esta área. Clique aqui para realizar Login',
          icon: <LockOutlined style={{ color: '#B22222' }} />,
          onClick: () => {
            history.replace('/');
            localStorage.clear();
          },
          duration: 1
        });
      }
      if (error[0].status === 422) {
        const valErrors = [];
        for (let i = 0; i < error[0].data.length; i++) {
          let message = error[0].data[i].message;
          if (message.includes('Description must be 120 to 160 characters.')) {
            message = 'Descrição deve ter entre 120 e 160 caracteres.';
          }
          else if (message.includes('URL is invalid.')) {
            message = 'A URL é inválida.';
          }
          valErrors.push(message);
        }
        if (validationErrors === undefined) {
          setValidationErrors(valErrors + '\n');
        } else {
          setValidationErrors(validationErrors + valErrors + '\n');
        }
      }
      if (error[0].status === 11000) {
        let err;
        if (error[0].message.includes('duplicate key error collection') === true) {
          err = 'Legenda duplicada.';
        }
        else {
          err = error[0].message;
        }

        if (validationErrors === undefined) {
          setValidationErrors(err + '\n');
        } else {
          setValidationErrors(validationErrors + err + '\n');
        }
      }
      if (error[0].status === 404) {
        if (id !== 'new') {
          let err;
          if (error[0].message.includes('No banner found!') === true) {
            err = 'Banner não encontrado.';
          }
          else {
            err = error[0].message;
          }

          if (validationErrors === undefined) {
            setValidationErrors(err + '\n');
          } else {
            setValidationErrors(validationErrors + err + '\n');
          }
        }
      }
      if (error[0].status === 500) {
        if (id !== 'new') {
          if (error[0].message.includes('Cast to ObjectId failed for value ') === true) {
            notification.open({
              message: 'Banner não encontrado',
              description:
                'Você foi direcionado para a página de Banners',
              icon: <BarcodeOutlined style={{ color: '#B22222' }} />,
              onClick: () => {
                history.replace('/banners/');
              },
              duration: 3
            });
            history.replace('/banners/');
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  let image = null;
  if (id !== 'new') {
    image = (
      <div>
        <div style={{ textAlign: 'center' }}>
          <h2>Imagem</h2>
          <Row gutter={16}>
            <Col span={8}></Col>
            <Col span={8}>
              <Card
                hoverable
                cover={<img title={legend} alt={legend} src={mainImage} />}
              >
                <Dragger {...propsUploadImage}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                </Dragger>
              </Card>
            </Col>
            <Col span={8}></Col>
          </Row>
        </div>
      </div>
    );
  }

  return (
    <Layout>
      <PageHeader
        ghost={false}
        onBack={() => history.replace('/banners/')}
        title="Banners"
        subTitle={legend === null ? 'Novo registro' : legend}></PageHeader>
      <Content style={{ marginTop: '25px', marginLeft: '20px', marginRight: '20px' }}>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Informações gerais" key="1">
            <Form {...formItemLayout} onFinish={handleSubmit} ref={formRef}>
              <Form.Item label="Legenda" name="legend"
                rules={[{
                  required: true,
                  message: 'Favor preencher a Legenda!'
                }]}>
                <Input />
              </Form.Item>
              <Form.Item label="URL" name="url"
                rules={[{
                  required: true,
                  message: 'Favor preencher a URL!'
                }]}>
                <Input />
              </Form.Item>
              {
                validationErrors !== '' ?
                  <Form.Item {...tailFormItemLayout}>
                    <Alert
                      message="Verifique o(s) seguinte(s) erro(s)"
                      description={validationErrors}
                      type="error"
                      showIcon
                    />
                  </Form.Item> : null
              }
              <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  Salvar
                            </Button>
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane tab="Imagem" key="2" disabled={id === 'new' ? true : false}>
            <Form {...formItemLayout}>
              {image}
            </Form>
          </TabPane>
        </Tabs>
      </Content>
      <Footer />
    </Layout >
  );
}

export default Banner;