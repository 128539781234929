import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { PageHeader, Layout, Form, Input, Button, notification, Alert } from 'antd';
import { CheckOutlined, LockOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

import useHttp from '../../../hooks/http';
import Footer from '../../../components/Footer/Footer';

const { Content } = Layout;

const Setting = () => {

  const { isLoading, error, data, sendRequest } = useHttp();

  const match = useRouteMatch('/settings/:id');
  const history = useHistory();
  const [id] = useState(match.params.id);
  const [validationErrors, setValidationErrors] = useState('');
  const [attribute, setAttribute] = useState('');
  const [isRequired, setIsRequired] = useState(false);

  const formRef = useRef();

  const handleSubmit = values => {
    setValidationErrors('');
    if (id !== 'new') {
      const graphqlQuery = {
        query: `
          mutation UpdateSetting ($settingId: ID!, $value: String){
            updateSetting(id: $settingId,
                settingInput:{
                  value: $value
                }
            )
            {
              id
              value
            }
          }
        `,
        variables: {
          settingId: id,
          value: values.value
        }
      };
      sendRequest(
        graphqlQuery,
        'UPDATE_CATEGORY',
      );
    }
    else {
      const graphqlQuery = {
        query: `
          mutation createSetting ($attribute: String!, $value: String!){
            createSetting(
              settingInput:{
                attribute: $attribute
                value: $value
              }
            )
            {
              id
              attribute
              value
            }
          }
        `,
        variables: {
          attribute: values.attribute,
          value: values.value
        }
      };
      sendRequest(
        graphqlQuery,
        'UPDATE_CATEGORY',
      );
    }
  }

  const onFillForm = args => {
    formRef.current.setFieldsValue({
      attribute: args.attribute,
      value: args.value
    });
  };

  useEffect(() => {
    if (id !== 'new') {
      const graphqlQuery = {
        query: `
          query Setting ($settingId: ID!){
            setting(id: $settingId){
              id
              attribute
              value
              isRequired
            }
          }
        `,
        variables: {
          settingId: id
        }
      };

      sendRequest(
        graphqlQuery,
        'GET_CATEGORY',
      );
    }
  }, [id, sendRequest]);

  useEffect(() => {
    if (data) {
      if (data.setting) {
        setAttribute(data.setting.attribute);
        setIsRequired(data.setting.isRequired);
        const args = [];
        args.attribute = data.setting.attribute;
        args.value = data.setting.value;
        onFillForm(args);
      }
      if (data.updateSetting) {
        notification.open({
          message: 'Registro atualizado com sucesso',
          icon: <CheckOutlined style={{ color: '#008000' }} />,
          duration: 2
        });
        history.replace('/settings/');
      }
      if (data.createSetting) {
        notification.open({
          message: 'Registro inserido com sucesso',
          icon: <CheckOutlined style={{ color: '#008000' }} />,
          duration: 2
        });
        history.replace('/settings/');
      }
    }
  }, [data, history]);

  useEffect(() => {
    if (error) {
      if (error[0].status === 401) {
        history.replace('/');
        localStorage.clear();
        notification.open({
          message: 'Não Autorizado!',
          description:
            'Você não tem autorização para acessar esta área. Clique aqui para realizar Login',
          icon: <LockOutlined style={{ color: '#B22222' }} />,
          onClick: () => {
            history.replace('/');
            localStorage.clear();
          },
          duration: 1
        });
      }
      if (error[0].status === 422) {
        const valErrors = [];
        for (let i = 0; i < error[0].data.length; i++) {
          let message = error[0].data[i].message;
          if (message.includes('Description must be 120 to 160 characters.')) {
            message = 'Descrição deve ter entre 120 e 160 caracteres.';
          }
          else if (message.includes(`Fill with 'true' or 'false'.`)) {
            message = `Preencha com 'true' ou 'false'.`;
          }
          valErrors.push(message);
        }
        if (validationErrors === undefined) {
          setValidationErrors(valErrors + '\n');
        } else {
          setValidationErrors(validationErrors + valErrors + '\n');
        }
      }
      if (error[0].status === 11000) {
        let err;
        if (error[0].message.includes('duplicate key error collection') === true) {
          err = 'Título duplicado.';
        }
        else {
          err = error[0].message;
        }

        if (validationErrors === undefined) {
          setValidationErrors(err + '\n');
        } else {
          setValidationErrors(validationErrors + err + '\n');
        }
      }
      if (error[0].status === 404) {
        if (id !== 'new') {
          let err;
          if (error[0].message.includes('No setting found!') === true) {
            err = 'Categoria não encontrada.';
          }
          else {
            err = error[0].message;
          }

          if (validationErrors === undefined) {
            setValidationErrors(err + '\n');
          } else {
            setValidationErrors(validationErrors + err + '\n');
          }
        }
      }
      if (error[0].status === 500) {
        if (id !== 'new') {
          if (error[0].message.includes('Cast to ObjectId failed for value ') === true) {
            notification.open({
              message: 'Categoria não encontrada',
              description:
                'Você foi direcionado para a página de Categorias',
              icon: <MenuUnfoldOutlined style={{ color: '#B22222' }} />,
              duration: 3
            });
            history.replace('/settings/');
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  return (
    <Layout>
      <PageHeader
        ghost={false}
        onBack={() => history.replace('/settings/')}
        title="Configurações"
        subTitle={attribute === null ? 'Novo registro' : attribute}></PageHeader>
      <Content style={{ marginTop: '25px', marginLeft: '20px', marginRight: '20px' }}>
        <Form {...formItemLayout} onFinish={handleSubmit} ref={formRef}>
          <Form.Item label="Valor" name="value"
            rules={[{
              required: isRequired,
              message: 'Favor preencher o Valor!'
            }]}>
            <Input />
          </Form.Item>
          {
            validationErrors !== '' ?
              <Form.Item {...tailFormItemLayout}>
                <Alert
                  message="Verifique o(s) seguinte(s) erro(s)"
                  description={validationErrors}
                  type="error"
                  showIcon
                />
              </Form.Item> : null
          }
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Salvar
                            </Button>
          </Form.Item>
        </Form>
      </Content>
      <Footer />
    </Layout>
  );
}

export default Setting;