import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { PageHeader, Layout, Form, Input, Button, notification, Alert } from 'antd';
import { CheckOutlined, LockOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

import useHttp from '../../../hooks/http';
import Footer from '../../../components/Footer/Footer';

const { Content } = Layout;

const Contact = () => {

  const { isLoading, error, data, sendRequest } = useHttp();

  const match = useRouteMatch('/contacts/:id');
  const history = useHistory();
  const [id] = useState(match.params.id);
  const [validationErrors, setValidationErrors] = useState('');
  const [name, setName] = useState('');

  const formRef = useRef();

  const handleSubmit = values => {
    setValidationErrors('');
    if (id !== 'new') {
      const graphqlQuery = {
        query: `
          mutation UpdateContact ($contactId: ID!, $name: String!, $email: String!){
            updateContact(id: $contactId,
              contactInput:{
                name: $name
                email: $email
              }
            )
            {
              id
              name
            }
          }
        `,
        variables: {
          contactId: id,
          name: values.name,
          email: values.email
        }
      };
      sendRequest(
        graphqlQuery,
        'UPDATE_CONTACT',
      );
    }
    else {
      const graphqlQuery = {
        query: `
          mutation createContact ($name: String!, $email: String!){
            createContact(
              contactInput:{
                name: $name
                email: $email
              }
            )
            {
              id
              name
            }
          }
        `,
        variables: {
          name: values.name,
          email: values.email
        }
      };
      sendRequest(
        graphqlQuery,
        'CREATE_CONTACT',
      );
    }
  }

  const onFillForm = args => {
    formRef.current.setFieldsValue({
      name: args.name,
      email: args.email
    });
  };

  const getContact = useCallback(() => {
    const graphqlQuery = {
      query: `
        query Contact ($contactId: ID!){
          contact(id: $contactId){
            id
            name
            email
            removedAt
          }
        }
      `,
      variables: {
        contactId: id
      }
    };

    sendRequest(
      graphqlQuery,
      'GET_CONTACT',
    );
  }, [id, sendRequest]);

  useEffect(() => {
    if (id !== 'new') {
      getContact();
    }
  }, [id, getContact]);

  useEffect(() => {
    if (data) {
      if (data.contact) {
        setName(data.contact.name);
        const args = [];
        args.name = data.contact.name;
        args.email = data.contact.email;
        onFillForm(args);
      }
      if (data.updateContact) {
        notification.open({
          message: 'Registro atualizado com sucesso',
          icon: <CheckOutlined style={{ color: '#008000' }} />,
          duration: 2
        });
        history.replace('/contacts/');
      }
      if (data.createContact) {
        notification.open({
          message: 'Registro inserido com sucesso',
          icon: <CheckOutlined style={{ color: '#008000' }} />,
          duration: 2
        });
        history.replace('/contacts/');
      }
    }
  }, [data, history]);

  useEffect(() => {
    if (error) {
      if (error[0].status === 401) {
        history.replace('/');
        localStorage.clear();
        notification.open({
          message: 'Não Autorizado!',
          description:
            'Você não tem autorização para acessar esta área. Clique aqui para realizar Login',
          icon: <LockOutlined style={{ color: '#B22222' }} />,
          onClick: () => {
            history.replace('/');
            localStorage.clear();
          },
          duration: 1
        });
      }
      if (error[0].status === 422) {
        const valErrors = [];
        for (let i = 0; i < error[0].data.length; i++) {
          valErrors.push(error[0].data[i].message);
        }
        if (validationErrors === undefined) {
          setValidationErrors(valErrors + '\n');
        } else {
          setValidationErrors(validationErrors + valErrors + '\n');
        }
      }
      if (error[0].status === 11000) {
        let err;
        if (error[0].message.includes('duplicate key error collection') === true) {
          err = 'Email duplicado.';
        }
        else {
          err = error[0].message;
        }

        if (validationErrors === undefined) {
          setValidationErrors(err + '\n');
        } else {
          setValidationErrors(validationErrors + err + '\n');
        }
      }
      if (error[0].status === 404) {
        if (id !== 'new') {
          let err;
          if (error[0].message.includes('No contact found!') === true) {
            err = 'Contato não encontrado.';
          }
          else {
            err = error[0].message;
          }

          if (validationErrors === undefined) {
            setValidationErrors(err + '\n');
          } else {
            setValidationErrors(validationErrors + err + '\n');
          }
        }
      }
      if (error[0].status === 500) {
        if (id !== 'new') {
          if (error[0].message.includes('Cast to ObjectId failed for value ') === true) {
            notification.open({
              message: 'Contato não encontrado',
              description:
                'Você foi direcionado para a página de Contatos',
              icon: <MenuUnfoldOutlined style={{ color: '#B22222' }} />,
              duration: 3
            });
            history.replace('/contacts/');
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  return (
    <Layout>
      <PageHeader
        ghost={false}
        onBack={() => history.replace('/contacts/')}
        title="Contatos"
        subTitle={name === null ? 'Novo registro' : name}></PageHeader>
      <Content style={{ marginTop: '25px', marginLeft: '20px', marginRight: '20px' }}>
        <Form {...formItemLayout} onFinish={handleSubmit} ref={formRef}>
          <Form.Item label="Nome" name="name"
            rules={[{
              required: true,
              message: 'Favor preencher o Nome!'
            }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Email" name="email"
            rules={[{
              required: true,
              message: 'Favor preencher o Email!'
            }]}>
            <Input />
          </Form.Item>
          {
            validationErrors !== '' ?
              <Form.Item {...tailFormItemLayout}>
                <Alert
                  message="Verifique o(s) seguinte(s) erro(s)"
                  description={validationErrors}
                  type="error"
                  showIcon
                />
              </Form.Item> : null
          }
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Salvar
                            </Button>
          </Form.Item>
        </Form>
      </Content>
      <Footer />
    </Layout>
  );
}

export default Contact;