import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { PageHeader, Layout, Form, Input, Button, notification, Alert, Switch } from 'antd';
import { CheckOutlined, LockOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

import useHttp from '../../../hooks/http';
import Footer from '../../../components/Footer/Footer';

const { Content } = Layout;

const Category = () => {

  const { isLoading, error, data, sendRequest } = useHttp();

  const match = useRouteMatch('/categories/:id');
  const history = useHistory();
  const [id] = useState(match.params.id);
  const [validationErrors, setValidationErrors] = useState('');
  const [title, setTitle] = useState('');
  const [isFeatured, setIsFeatured] = useState(true);

  const onIsFeaturedChange = checked => {
    setIsFeatured(checked);
  };

  const formRef = useRef();

  const handleSubmit = values => {
    setValidationErrors('');
    if (id !== 'new') {
      const graphqlQuery = {
        query: `
          mutation UpdateCategory ($categoryId: ID!, $title: String!, $isFeatured: Boolean!){
            updateCategory(id: $categoryId,
              categoryInput:{
                title: $title
                isFeatured: $isFeatured
              }
            )
            {
              id
              title
              isFeatured
            }
          }
        `,
        variables: {
          categoryId: id,
          title: values.title,
          isFeatured: isFeatured
        }
      };
      sendRequest(
        graphqlQuery,
        'UPDATE_CATEGORY',
      );
    }
    else {
      const graphqlQuery = {
        query: `
          mutation createCategory ($title: String!, $isFeatured: Boolean!){
            createCategory(
              categoryInput:{
                title: $title
                isFeatured: $isFeatured
              }
            )
            {
              id
              title
              isFeatured
            }
          }
        `,
        variables: {
          title: values.title,
          isFeatured: isFeatured
        }
      };
      sendRequest(
        graphqlQuery,
        'UPDATE_CATEGORY',
      );
    }
  }

  const onFillForm = args => {
    setIsFeatured(args.isFeatured);
    formRef.current.setFieldsValue({
      title: args.title
    });
  };

  useEffect(() => {
    if (id !== 'new') {
      const graphqlQuery = {
        query: `
          query Category ($categoryId: ID!){
            category(id: $categoryId){
              id
              title
              isFeatured
            }
          }
        `,
        variables: {
          categoryId: id
        }
      };

      sendRequest(
        graphqlQuery,
        'GET_CATEGORY',
      );
    }
  }, [id, sendRequest]);

  useEffect(() => {
    if (data) {
      if (data.category) {
        setTitle(data.category.title);
        const args = [];
        args.title = data.category.title;
        args.isFeatured = data.category.isFeatured;
        onFillForm(args);
      }
      if (data.updateCategory) {
        notification.open({
          message: 'Registro atualizado com sucesso',
          icon: <CheckOutlined style={{ color: '#008000' }} />,
          duration: 2
        });
        history.replace('/categories/');
      }
      if (data.createCategory) {
        notification.open({
          message: 'Registro inserido com sucesso',
          icon: <CheckOutlined style={{ color: '#008000' }} />,
          duration: 2
        });
        history.replace('/categories/');
      }
    }
  }, [data, history]);

  useEffect(() => {
    if (error) {
      if (error[0].status === 401) {
        history.replace('/');
        localStorage.clear();
        notification.open({
          message: 'Não Autorizado!',
          description:
            'Você não tem autorização para acessar esta área. Clique aqui para realizar Login',
          icon: <LockOutlined style={{ color: '#B22222' }} />,
          onClick: () => {
            history.replace('/');
            localStorage.clear();
          },
          duration: 1
        });
      }
      if (error[0].status === 422) {
        const valErrors = [];
        for (let i = 0; i < error[0].data.length; i++) {
          let message = error[0].data[i].message;
          if (message.includes('Description must be 120 to 160 characters.'))
            message = 'Descrição deve ter entre 120 e 160 caracteres.';
          valErrors.push(message);
        }
        if (validationErrors === undefined) {
          setValidationErrors(valErrors + '\n');
        } else {
          setValidationErrors(validationErrors + valErrors + '\n');
        }
      }
      if (error[0].status === 11000) {
        let err;
        if (error[0].message.includes('duplicate key error collection') === true) {
          err = 'Título duplicado.';
        }
        else {
          err = error[0].message;
        }

        if (validationErrors === undefined) {
          setValidationErrors(err + '\n');
        } else {
          setValidationErrors(validationErrors + err + '\n');
        }
      }
      if (error[0].status === 404) {
        if (id !== 'new') {
          let err;
          if (error[0].message.includes('No category found!') === true) {
            err = 'Categoria não encontrada.';
          }
          else {
            err = error[0].message;
          }

          if (validationErrors === undefined) {
            setValidationErrors(err + '\n');
          } else {
            setValidationErrors(validationErrors + err + '\n');
          }
        }
      }
      if (error[0].status === 500) {
        if (id !== 'new') {
          if (error[0].message.includes('Cast to ObjectId failed for value ') === true) {
            notification.open({
              message: 'Categoria não encontrada',
              description:
                'Você foi direcionado para a página de Categorias',
              icon: <MenuUnfoldOutlined style={{ color: '#B22222' }} />,
              duration: 3
            });
            history.replace('/categories/');
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  return (
    <Layout>
      <PageHeader
        ghost={false}
        onBack={() => history.replace('/categories/')}
        title="Categorias"
        subTitle={title === null ? 'Novo registro' : title}></PageHeader>
      <Content style={{ marginTop: '25px', marginLeft: '20px', marginRight: '20px' }}>
        <Form {...formItemLayout} onFinish={handleSubmit} ref={formRef}>
          <Form.Item label="Título" name="title"
            rules={[{
              required: true,
              message: 'Favor preencher o Título!'
            }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Destaque">
            <Switch checked={isFeatured} onChange={onIsFeaturedChange} />
          </Form.Item>
          {
            validationErrors !== '' ?
              <Form.Item {...tailFormItemLayout}>
                <Alert
                  message="Verifique o(s) seguinte(s) erro(s)"
                  description={validationErrors}
                  type="error"
                  showIcon
                />
              </Form.Item> : null
          }
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Salvar
                            </Button>
          </Form.Item>
        </Form>
      </Content>
      <Footer />
    </Layout>
  );
}

export default Category;