import * as types from '../types';

export const fetchKeywords = () => async dispatch => {
  dispatch({
    type: types.GET_KEYWORDS,
    payload: await []
  })
}

export const fetchKeywordsSuccess = (filter, sortField, sortOrder, page, pageSize) => async dispatch => {

  const fetchData = async (filter, sortField, sortOrder, page = 1, pageSize = 10) => {
    const graphqlQuery = {
      query: `
        query Keywords($filter: String, $sortField: String, $sortOrder: String, $page: Int, $pageSize: Int) {
          keywords(filter: $filter, sortField: $sortField, sortOrder: $sortOrder, page: $page, pageSize: $pageSize){
            keywords{
              id
              title
              createdAt
              updatedAt
            }
            totalRecords
          }
        }
      `,
      variables: {
        filter,
        sortField,
        sortOrder,
        page,
        pageSize
      }
    };
    await fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      body: JSON.stringify(graphqlQuery)
    })
      .then(r => r.json())
      .then(data => {
        if (data.data) {
          dispatch({
            type: types.GET_KEYWORDS_SUCCESS,
            payload: data.data.keywords
          });
        }
        if (data.errors) {
          dispatch({
            type: types.GET_KEYWORDS_SUCCESS,
            error: data.errors
          });
        }
      });
  };
  await fetchData(filter, sortField, sortOrder, page, pageSize);
}

export const deleteKeyword = (id) => async dispatch => {
  const deleteKeyword = async (id) => {
    const graphqlQuery = {
      query: `
        mutation DeleteKeyword($id: ID!) {
          deleteKeyword(id: $id)
        }
      `,
      variables: {
        id
      }
    };
    await fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      body: JSON.stringify(graphqlQuery)
    })
      .then(r => r.json())
      .then(data => {
        if (data.data) {
          dispatch({
            type: types.DELETE_KEYWORD,
            deleted: data.data.deleteKeyword,
            error: null
          });
        }
        if (data.errors) {
          dispatch({
            type: types.DELETE_KEYWORD,
            deleted: false,
            error: data.errors
          });
        }
      });
  };
  await deleteKeyword(id);
}