import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import pt_BR from 'antd/es/locale/pt_BR';

import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <BrowserRouter>
    <ConfigProvider locale={pt_BR}>
      <App />
    </ConfigProvider>
  </BrowserRouter>
  , document.getElementById('root'));

serviceWorker.unregister();
