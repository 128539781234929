import React, { useState, useEffect, useRef } from 'react';
import { Route, NavLink, Switch, useHistory } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import { Layout, Menu, notification, Modal, Spin, Form, Input, Button, Alert, Switch as Checkbox } from 'antd';
import {
  BarcodeOutlined, MenuUnfoldOutlined, TagsOutlined,
  BorderHorizontalOutlined, RedoOutlined, MailOutlined, LoginOutlined, LogoutOutlined,
  CheckOutlined, HomeFilled, LockOutlined, UserOutlined, WarningFilled, PictureOutlined
} from '@ant-design/icons';

import Banners from './containers/Banners/Banners';
import Banner from './containers/Banners/Banner/Banner';
import Categories from './containers/Categories/Categories';
import Category from './containers/Categories/Category/Category';
import Pages from './containers/Pages/Pages';
import Page from './containers/Pages/Page/Page';
import Keyword from './containers/Keywords/Keyword/Keyword';
import Keywords from './containers/Keywords/Keywords';
import Product from './containers/Products/Product/Product';
import Products from './containers/Products/Products';
import ChangePassword from './containers/ChangePassword/ChangePassword';
import Main from './containers/Main/Main';
import Contacts from './containers/Contacts/Contacts';
import Contact from './containers/Contacts/Contact/Contact';
import Settings from './containers/Settings/Settings';
import Setting from './containers/Settings/Setting/Setting';
import NoMatch from './components/NoMatch/NoMatch';
import Logout from './containers/Logout/Logout';
import useHttp from './hooks/http';
import Logo from './images/logo.png';

import 'antd/dist/antd.css';

const { Sider, Content } = Layout;

const App = () => {

  const [collapsed, setCollapsed] = useState(false);
  const [visibleReset, setVisibleReset] = useState(false);
  const [visibleLogin, setVisibleLogin] = useState(false);

  const { isLoading, error, data, sendRequest } = useHttp();
  const [validationErrors, setValidationErrors] = useState('');
  const [recoveryPassword, setRecoveryPassword] = useState(false);
  const [loginSuccessfully, setLoginSuccessfully] = useState(false);
  const [button, setButton] = useState('Login');

  const formRef = useRef();
  const history = useHistory();

  const onRecoveryPasswordChange = checked => {
    setRecoveryPassword(checked);
    if (checked) {
      setButton('Recuperar senha');
    }
    else {
      setButton('Login');
    }
  };

  const handleSubmit = values => {
    setValidationErrors('');
    if (recoveryPassword) {
      const graphqlQuery = {
        query: `
          mutation ($email: String!) {
            recoveryPassword(email: $email) {
              id
            }
          }
        `,
        variables: {
          email: values.email.trim()
        }
      };
      sendRequest(
        graphqlQuery,
        'RECOVER_PASSWORD',
      );
    }
    else {
      const graphqlQuery = {
        query: `
          query UserLogin($email: String!, $password: String!) {
            login(email: $email, password: $password) {
              token
              userId
            }
          }
        `,
        variables: {
          email: values.email.trim(),
          password: values.password.trim()
        }
      };
      sendRequest(
        graphqlQuery,
        'LOGIN',
      );
    }
  };

  const modalReset = () => {
    setVisibleReset(true)
  };

  const modalLogin = () => {
    setVisibleLogin(true)
  };

  const onCollapse = collapsed => {
    setCollapsed(collapsed)
  };

  useEffect(() => {
    const graphqlQuery = {
      query: `
        query checkUser {
          checkUser {
            id
          }
        }
      `
    };
    sendRequest(
      graphqlQuery,
      'CHECK_USER',
    );
  }, [sendRequest]);

  useEffect(() => {
    if (data) {
      if (data.productsActivated) {
        const products = data.productsActivated;
        let categoryEmpty = false;
        let keywordEmpty = false;
        let imagesEmpty = false;
        for (let i = 0; i < products.length; i++) {
          if (products[i].totalCategories === 0) {
            categoryEmpty = true;
          }
          if (products[i].totalKeywords === 0) {
            keywordEmpty = true;
          }
          if (products[i].totalImages === 0) {
            imagesEmpty = true;
          }
        }
        if (!categoryEmpty && !keywordEmpty && !imagesEmpty) {
          fetch(`https://api.netlify.com/build_hooks/5f7f9c7caf645b133e5da741`, {
            method: 'POST',
          });
          notification.open({
            message: 'Atualização feita com sucesso! Aguarde 5 minutos para visualizar.',
            icon: <CheckOutlined style={{ color: '#008000' }} />,
            duration: 5
          });
        }
        else {
          notification.open({
            message: 'Erro ao Atualizar site',
            description:
              'Verifique se todos produtos estão com pelo menos uma categoria, uma palavra-chave e uma imagem secundária',
            icon: <WarningFilled style={{ color: '#B22222' }} />,
            duration: 10
          });
        }
        setVisibleReset(false);
      }
      if (data.login) {
        setLoginSuccessfully(true);
        localStorage.setItem('token', data.login.token);
        localStorage.setItem('userId', data.login.userId);
        const remainingMilliseconds = 60 * 60 * 12000;
        const expiryDate = new Date(
          new Date().getTime() + remainingMilliseconds
        );
        localStorage.setItem('expiryDate', expiryDate.toISOString());
        setVisibleLogin(false);
        formRef.current.setFieldsValue({
          email: '',
          password: ''
        });
        notification.open({
          message: 'Usuário(a) autenticado(a) com sucesso!',
          icon: <CheckOutlined style={{ color: '#008000' }} />,
          duration: 2
        });
      }
      if (data.recoveryPassword) {
        notification.open({
          message: 'Instruções para alteração de senha enviada por email!',
          icon: <CheckOutlined style={{ color: '#008000' }} />,
          duration: 5
        });
      }
      if (data.checkUser) {
        setVisibleLogin(false);
        setLoginSuccessfully(true);
      }
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      if (error[0].status) {
        // if (error[0].status) {
        //   notification.open({
        //     message: 'Erro ao Atualizar site',
        //     description:
        //       error[0].message,
        //     icon: <WarningFilled style={{ color: '#B22222' }} />,
        //     duration: 10
        //   });
        // }
        setVisibleReset(false);

        if (error[0].status === 422) {
          const valErrors = [];
          for (let i = 0; i < error[0].data.length; i++) {
            let message = error[0].data[i].message;
            if (message.includes('New password and Confirm new password is not equals.'))
              message = 'Nova senha e Confirmação da nova nenha estão diferentes, favor verificar.';
            valErrors.push(message);
          }
          if (validationErrors === undefined) {
            setValidationErrors(valErrors + '\n');
          } else {
            setValidationErrors(validationErrors + valErrors + '\n');
          }
        }
        if (error[0].status === 404) {
          let err = error[0].message;
          if (error[0].message.includes('No user found!')) {
            err = 'Usuário não encontrado.';
          }


          if (validationErrors === undefined) {
            setValidationErrors(err + '\n');
          } else {
            setValidationErrors(validationErrors + err + '\n');
          }
        }
        if (error[0].status === 401) {
          setVisibleLogin(true);
        }
      }
    }
  }, [error, validationErrors]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  const resetData = () => {

    const graphqlQuery = {
      query: `
        query ProductsActivated  {
          productsActivated {
            products {
              totalCategories
              totalKeywords
              totalImages
            }
          }
        }
      `
    };

    sendRequest(
      graphqlQuery,
      'GET_PRODUCTS',
    );
  };

  const resetCancel = e => {
    setVisibleReset(false);
  };

  const redirect = () => {
    window.open('https://indall.com.br', '_blank');
  };

  const logout = () => {
    localStorage.clear();
    setLoginSuccessfully(false);
    setVisibleLogin(true);
    history.replace('/');
  };

  return (
    <Provider store={store}>
      <Layout style={{ minHeight: '100vh' }}>
        <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
          <div className="logo" />
          <div style={{ marginLeft: '25px', marginRight: '25px', paddingTop: '25px', marginBottom: '10px', }}>
            <img src={Logo} alt="Click Soluções WEB" title="Click Soluções WEB" style={{ width: '100%' }} />
          </div>
          {loginSuccessfully ?
            (<Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">

              <Menu.Item key="products">
                <NavLink to="/products/">
                  <BarcodeOutlined />
                  <span>Produtos</span>
                </NavLink>
              </Menu.Item>

              <Menu.Item key="categories">
                <NavLink to="/categories/">
                  <MenuUnfoldOutlined />
                  <span>Categorias</span>
                </NavLink>
              </Menu.Item>

              <Menu.Item key="contacts">
                <NavLink to="/contacts/">
                  <UserOutlined />
                  <span>Contatos</span>
                </NavLink>
              </Menu.Item>

              <Menu.Item key="keywords">
                <NavLink to="/keywords/">
                  <TagsOutlined />
                  <span>Palavras-chave</span>
                </NavLink>
              </Menu.Item>

              <Menu.Item key="banners">
                <NavLink to="/banners/">
                  <PictureOutlined />
                  <span>Banners</span>
                </NavLink>
              </Menu.Item>

              <Menu.Item key="pages">
                <NavLink to="/pages/">
                  <BorderHorizontalOutlined />
                  <span>Páginas</span>
                </NavLink>
              </Menu.Item>

              {/* <Menu.Item key="settings">
                <NavLink to="/settings/">
                  <SettingFilled />
                  <span>Configurações</span>
                </NavLink>
              </Menu.Item> */}

              <Menu.Item key="changePassword">
                <NavLink to="/changepassword/">
                  <LockOutlined />
                  <span>Alterar senha</span>
                </NavLink>
              </Menu.Item>

              <Menu.Item key="resetData" onClick={() => modalReset()}>
                <RedoOutlined />
                <span>Atualizar site</span>
              </Menu.Item>

              <Modal
                title="Atualização de conteúdo"
                visible={visibleReset}
                onOk={resetData}
                onCancel={resetCancel}
                okText='Sim'
                cancelText='Não'
              >
                {isLoading ? <Spin /> : (<p>Atualizar conteúdo do site?</p>)}
              </Modal>

              <Menu.Item key="redirect" onClick={redirect}>
                <HomeFilled />
                <span>Ir ao site</span>
              </Menu.Item>

              <Menu.Item key="logout" onClick={() => logout()}>
                <LogoutOutlined />
                <span>Sair</span>
              </Menu.Item>

            </Menu>) :
            (<Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
              <Menu.Item key="login" onClick={() => modalLogin()}>
                <LoginOutlined />
                <span>Login</span>
              </Menu.Item>
            </Menu>)}
        </Sider>
        <Modal
          title="Login"
          visible={visibleLogin}
          onOk={handleSubmit}
          okButtonProps={{ style: { display: 'none' } }}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <Content style={{ marginTop: '25px', marginLeft: '20px', marginRight: '20px' }}>
            <Form {...formItemLayout} onFinish={handleSubmit} className="login-form" ref={formRef}>
              <Form.Item label="Email" name="email"
                rules={[{
                  required: true,
                  message: 'Entre com seu email!'
                }]}>
                <Input
                  prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Email"
                />
              </Form.Item>
              <Form.Item label="Senha" name="password"
                rules={[{
                  required: !recoveryPassword,
                  message: 'Entre com sua senha!'
                }]}>
                <Input
                  prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  placeholder="Senha"
                />
              </Form.Item>
              <Form.Item label="Recuperar senha">
                <Checkbox checked={recoveryPassword} onChange={onRecoveryPasswordChange} />
              </Form.Item>
              {
                validationErrors !== '' ?
                  <Form.Item {...tailFormItemLayout}>
                    <Alert
                      message="Verifique o(s) seguinte(s) erro(s)"
                      description={validationErrors}
                      type="error"
                      showIcon
                    />
                  </Form.Item> : null
              }
              <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" className="login-form-button" loading={isLoading}>
                  {button}
                </Button>
                {/* Or <a href="#!">register now!</a> */}
              </Form.Item>
            </Form>
          </Content>
        </Modal>
        <Switch>
          <Route path="/banners/:id" exact component={Banner} />
          <Route path="/banners/" component={Banners} />
          <Route path="/categories/:id" exact component={Category} />
          <Route path="/categories/" component={Categories} />
          <Route path="/contacts/:id" exact component={Contact} />
          <Route path="/contacts/" component={Contacts} />
          <Route path="/pages/:id" exact component={Page} />
          <Route path="/pages/" component={Pages} />
          <Route path="/keywords/:id" component={Keyword} />
          <Route path="/keywords/" component={Keywords} />
          <Route path="/changepassword/" component={ChangePassword} />
          <Route path="/contacts/:id" component={Contact} />
          <Route path="/contacts/" component={Contacts} />
          <Route path="/products/:id" component={Product} />
          <Route path="/products/" component={Products} />
          <Route path="/settings/:id" component={Setting} />
          <Route path="/settings/" component={Settings} />
          <Route path="/logout/" exact component={Logout} />
          <Route path="/" exact component={Main} />
          <Route component={NoMatch} />
        </Switch>
      </Layout>
    </Provider>
  );
}

export default App;
