import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  PageHeader, Layout, Form, Input, InputNumber, Button,
  notification, Alert, Upload, Switch, Transfer, Card,
  message, Col, Row, Modal, Tabs
} from 'antd';
import {
  CheckOutlined, LockOutlined, InboxOutlined, BarcodeOutlined,
  DeleteFilled//, WarningFilled
} from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';

import useHttp from '../../../hooks/http';
import Footer from '../../../components/Footer/Footer';

const { Content } = Layout;
const { Dragger } = Upload;
const { TabPane } = Tabs;

const Product = () => {

  const { isLoading, error, data, sendRequest } = useHttp();

  const match = useRouteMatch('/products/:id');
  const history = useHistory();
  const [id] = useState(match.params.id);
  const [validationErrors, setValidationErrors] = useState('');
  const [title, setTitle] = useState('');
  const [isActivated, setIsActivated] = useState(true);
  const [isFeatured, setIsFeatured] = useState(true);
  const [imageList, setImageList] = useState([]);
  const [mainImage, setMainImage] = useState('');
  const [categories, setCategories] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [imageToDelete, setImageToDelete] = useState('');

  const propsUploadImage = {
    name: 'image',
    action: `${process.env.REACT_APP_API_URL}/image`,
    multiple: false,
    method: 'PUT',
    onChange(info) {
      if (info.file.status === 'done') {
        message.success(`${info.file.name} carregado com sucesso!`);

        setMainImage(info.fileList[info.fileList.length - 1].response.file);
        console.log(0, info.fileList[info.fileList.length - 1].response.file);
        let uploadedFiles = info.fileList[info.fileList.length - 1].response.file;
        console.log('Uploaded Files', uploadedFiles);
        const graphqlQuery = {
          query: `
            mutation StorageProductImage ($productId: ID!, $originalFile: String!){
              storageProductImage (id: $productId, 
                storageProductImageInput:{
                  originalFile: $originalFile
                }
              )
              {
                image
              }
            }
          `,
          variables: {
            productId: id,
            originalFile: info.fileList[info.fileList.length - 1].response.file,
          }
        };
        sendRequest(
          graphqlQuery,
          'UPDATE_IMAGE',
        );
      }
      else if (info.file.status === 'error') {
        message.error(`Erro ao carregar arquivo ${info.file.name}.`);
      }
    },
  };

  const propsUploadImages = {
    name: 'image',
    action: `${process.env.REACT_APP_API_URL}/image`,
    multiple: false,
    method: 'PUT',
    onChange(info) {
      if (info.file.status === 'done') {
        message.success(`${info.file.name} carregado com sucesso!`);

        let uploadedFiles = info.fileList[info.fileList.length - 1].response.file;
        console.log('Uploaded Files', uploadedFiles);
        const graphqlQuery = {
          query: `
            mutation StorageProductImages ($productId: ID!, $files: String!){
              storageProductImages (id: $productId, 
                storageProductImagesInput:{
                  files: $files
                }
              )
              {
                status
              }
            }
          `,
          variables: {
            productId: id,
            files: uploadedFiles
          }
        };
        sendRequest(
          graphqlQuery,
          'UPDATE_IMAGE',
        );
      }
      else if (info.file.status === 'error') {
        message.error(`Erro ao carregar arquivo ${info.file.name}.`);
      }
    },
  };

  const selectToDelete = image => {
    setVisibleDelete(!visibleDelete);
    setImageToDelete(image);
  };

  const deleteData = () => {
    // if (imageList.length === 1) {
    //     notification.open({
    //         message: 'Erro ao excluir imagem secundária',
    //         description:
    //             'É necessário pelo menos uma imagem secundária.',
    //         icon: <WarningFilled style={{ color: '#B22222' }} />,
    //         duration: 4
    //     });
    //     setVisibleDelete(false);
    // }
    // else {
    const graphqlQuery = {
      query: `
        mutation DeleteProductImage ($productId: ID!, $image: String!){
          deleteProductImage(id: $productId, image: $image)
        }
      `,
      variables: {
        productId: id,
        image: imageToDelete
      }
    };
    sendRequest(
      graphqlQuery,
      'DELETE_IMAGE',
    );
    // }
  };

  const deleteCancel = () => {
    setVisibleDelete(!visibleDelete);
  };

  const formRef = useRef();

  const [targetCategories, setTargetCategories] = useState([]);
  const [targetKeywords, setTargetKeywords] = useState([]);

  const onIsActivatedChange = checked => {
    setIsActivated(checked);
  };

  const onIsFeaturedChange = checked => {
    setIsFeatured(checked);
  };

  const handleSubmit = values => {
    setValidationErrors('');
    if (id !== 'new') {
      // UPDATE
      const graphqlQuery = {
        query: `
        mutation UpdateProduct ($productId: ID!, $title: String!, 
          $description: String!, $details: String!, 
          $price: Float!, 
          $isActivated: Boolean!,
          $isFeatured: Boolean!){
          updateProduct(id: $productId,
            productInput:{
              title: $title
              description: $description
              details: $details
              price: $price
              isActivated: $isActivated
              isFeatured: $isFeatured
            }
          )
          {
            id
            title
            description
            details
            price
            isActivated
            isFeatured
          }
        }
        `,
        variables: {
          productId: id,
          title: values.title,
          description: values.description,
          details: values.details,
          price: values.price,
          isActivated: isActivated,
          isFeatured: isFeatured
        }
      };
      sendRequest(
        graphqlQuery,
        'UPDATE_PRODUCT',
      );
    }
    else {
      const graphqlQuery = {
        query: `
          mutation createProduct (
            $title: String!, $description: String!, $details: String!, 
            $price: Float!, 
            $isActivated: Boolean!,
            $isFeatured: Boolean!){
            createProduct(
              productInput:{
                title: $title
                description: $description
                details: $details
                price: $price
                isActivated: $isActivated
                isFeatured: $isFeatured
              }
            )
            {
              id
              title
              description
              details
              price
              isActivated
              isFeatured
            }
          }
        `,
        variables: {
          productId: id,
          title: values.title,
          description: values.description,
          details: values.details,
          price: values.price,
          isActivated: isActivated,
          isFeatured: isFeatured
        }
      };
      sendRequest(
        graphqlQuery,
        'CREATE_PRODUCT',
      );
    }
  };

  const onFillForm = args => {
    setIsActivated(args.isActivated);
    setIsFeatured(args.isFeatured);
    formRef.current.setFieldsValue({
      title: args.title,
      description: args.description,
      details: args.details,
      price: args.price
    });
  };

  const filterOption = (inputValue, option) => option.title.indexOf(inputValue) > -1;

  const handleCategoriesChange = (targetKeys, direction, moveKeys) => {
    if (direction === 'left') {
      // DELETE
      let array = '';
      for (let i = 0; i < moveKeys.length; i++) {
        array += `{productId: "${id}", categoryId:"${moveKeys[i]}"}, `;
      }
      const graphqlQuery = {
        query: `                    
          mutation { deleteProductCategoryMultiple(productCategoryInput: 
              [
                ${array}
              ]
            )
          }
        `
      };
      sendRequest(
        graphqlQuery,
        'DELETE_PRODUCT_CATEGORY',
      );
    }
    else if (direction === 'right') {
      // INSERT
      let array = '';
      for (let i = 0; i < moveKeys.length; i++) {
        array += `{productId: "${id}", categoryId:"${moveKeys[i]}"}, `;
      }
      const graphqlQuery = {
        query: `                    
          mutation { createProductCategoryMultiple(productCategoryInput: 
              [
               ${array}
              ]
            )
          }
       `
      };
      sendRequest(
        graphqlQuery,
        'CREATE_PRODUCT_CATEGORY',
      );
    }
  };

  const handleKeywordsChange = (targetKeys, direction, moveKeys) => {
    if (direction === 'left') {
      // DELETE
      let array = '';
      for (let i = 0; i < moveKeys.length; i++) {
        array += `{productId: "${id}", keywordId:"${moveKeys[i]}"}, `;
      }
      const graphqlQuery = {
        query: `                    
          mutation { deleteProductKeywordMultiple(productKeywordInput: 
              [
              ${array}
              ]
            )
          }
        `
      };
      sendRequest(
        graphqlQuery,
        'DELETE_PRODUCT_KEYWORD',
      );
    }
    else if (direction === 'right') {
      // INSERT
      let array = '';
      for (let i = 0; i < moveKeys.length; i++) {
        array += `{productId: "${id}", keywordId:"${moveKeys[i]}"}, `;
      }
      const graphqlQuery = {
        query: `                    
          mutation { createProductKeywordMultiple(productKeywordInput: 
              [
              ${array}
              ]
            )
          }
        `
      };
      sendRequest(
        graphqlQuery,
        'CREATE_PRODUCT_KEYWORD',
      );
    }
  };

  const getCategories = useCallback(() => {
    const graphqlQuery = {
      query: `
        query Categories {
          categories (sortField: "title", sortOrder: "ASC"){
            categories{
              id
              title
            }
            totalRecords
          }
        }
      `,
    };

    sendRequest(
      graphqlQuery,
      'GET_CATEGORIES',
    );
  }, [sendRequest]);

  const getKeywords = useCallback(() => {
    const graphqlQuery = {
      query: `
        query Keywords {
          keywords (sortField: "title", sortOrder: "ASC"){
            keywords{
              id
              title
            }
            totalRecords
          }
        }
      `,
    };

    sendRequest(
      graphqlQuery,
      'GET_KEYWORDS',
    );
  }, [sendRequest]);

  const getProduct = useCallback(() => {
    const graphqlQuery = {
      query: `
        query Product ($productId: ID!){
          product(id: $productId){
            id
            title
            description
            details
            price
            image
            imageThumbnail
            images
            imagesThumbnail
            isActivated
            isFeatured
            categories{
              id
              title
            }
            keywords{
              id
              title
            }
          }
        }
      `,
      variables: {
        productId: id
      }
    };

    sendRequest(
      graphqlQuery,
      'GET_PRODUCT',
    );
  }, [id, sendRequest]);

  useEffect(() => {
    if (id !== 'new') {
      getProduct();
    }

    getCategories();
    getKeywords();
    getProduct();

  }, [id, getCategories, getKeywords, getProduct, sendRequest]);

  useEffect(() => {
    if (data) {
      if (data.product) {
        setTitle(data.product.title);
        setImageList(data.product.imagesThumbnail);
        const args = [];
        args.title = data.product.title;
        args.description = data.product.description;
        args.details = data.product.details;
        args.price = data.product.price;
        setMainImage(data.product.imageThumbnail);
        console.log(1, data.product.imageThumbnail);
        args.isActivated = data.product.isActivated;
        args.isFeatured = data.product.isFeatured;
        onFillForm(args);

        const targetKeysCategories = [];
        const categories = data.product.categories;
        for (let i = 0; i < categories.length; i++) {
          targetKeysCategories.push(categories[i].id);
        }
        setTargetCategories(targetKeysCategories);

        const targetKeysKeywords = [];
        const keywords = data.product.keywords;
        for (let i = 0; i < keywords.length; i++) {
          targetKeysKeywords.push(keywords[i].id);
        }
        setTargetKeywords(targetKeysKeywords);
      }
      if (data.updateProduct) {
        notification.open({
          message: 'Registro atualizado com sucesso',
          icon: <CheckOutlined style={{ color: '#008000' }} />,
          duration: 2
        });
        history.replace('/products/');
      }
      if (data.createProduct) {
        notification.open({
          message: 'Registro inserido com sucesso',
          icon: <CheckOutlined style={{ color: '#008000' }} />,
          duration: 2
        });
        history.replace('/products/');
      }
      if (data.categories) {
        const categories = data.categories.categories.map(item => {
          return {
            ...item,
            key: item.id
          }
        });
        setCategories(categories);
      }
      if (data.keywords) {
        const keywords = data.keywords.keywords.map(item => {
          return {
            ...item,
            key: item.id
          }
        });
        setKeywords(keywords);
      }
      if (data.createProductCategoryMultiple) {
        if (data.createProductCategoryMultiple > 0) {
          notification.open({
            message: data.createProductCategoryMultiple === 0 ?
              'Uma categoria foi vinculada com sucesso!' :
              `${data.createProductCategoryMultiple} categorias foram vinculadas com sucesso!`,
            icon: <CheckOutlined style={{ color: '#008000' }} />,
            duration: 2
          });
        }
        getProduct();
        getCategories();
      }
      if (data.deleteProductCategoryMultiple) {
        if (data.deleteProductCategoryMultiple > 0) {
          notification.open({
            message: data.deleteProductCategoryMultiple === 0 ?
              'Uma categoria foi desvinculada com sucesso!' :
              `${data.deleteProductCategoryMultiple} categorias foram desvinculadas com sucesso!`,
            icon: <CheckOutlined style={{ color: '#008000' }} />,
            duration: 2
          });
        }
        getProduct();
        getCategories();
      }
      if (data.createProductKeywordMultiple) {
        if (data.createProductKeywordMultiple > 0) {
          notification.open({
            message: data.createProductKeywordMultiple === 0 ?
              'Uma palavra-chave foi vinculada com sucesso!' :
              `${data.createProductKeywordMultiple} palavras-chave foram vinculadas com sucesso!`,
            icon: <CheckOutlined style={{ color: '#008000' }} />,
            duration: 2
          });
        }
        getProduct();
        getKeywords();
      }
      if (data.deleteProductKeywordMultiple) {
        if (data.deleteProductKeywordMultiple > 0) {
          notification.open({
            message: data.deleteProductKeywordMultiple === 0 ?
              'Uma palavra-chave foi desvinculada com sucesso!' :
              `${data.deleteProductKeywordMultiple} palavras-chave foram desvinculadas com sucesso!`,
            icon: <CheckOutlined style={{ color: '#008000' }} />,
            duration: 2
          });
        }
        getProduct();
        getKeywords();
      }
      if (data.storageProductImage) {
        getProduct();
        notification.open({
          message: `Imagem principal atualizada com sucesso!`,
          icon: <CheckOutlined style={{ color: '#008000' }} />,
          duration: 4
        });
      }
      if (data.storageProductImages) {
        let status = data.storageProductImages.status;
        if (status.includes('file(s) successfully uploaded!')) {
          status = status.replace('file(s) successfully uploaded!', 'arquivo(s) carregado(s) com sucesso!');
          getProduct();
        }
        notification.open({
          message: `${status}`,
          icon: <CheckOutlined style={{ color: '#008000' }} />,
          duration: 4
        });
      }
      if (data.deleteProductImage) {
        getProduct();
        setVisibleDelete(!visibleDelete);
        notification.open({
          message: `Imagem excluída com sucesso!`,
          icon: <CheckOutlined style={{ color: '#008000' }} />,
          duration: 4
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, getCategories, getKeywords, getProduct, history]);

  useEffect(() => {
    if (error) {
      if (error[0].status === 401) {
        history.replace('/');
        localStorage.clear();
        notification.open({
          message: 'Não Autorizado!',
          description:
            'Você não tem autorização para acessar esta área. Clique aqui para realizar Login',
          icon: <LockOutlined style={{ color: '#B22222' }} />,
          onClick: () => {
            history.replace('/');
            localStorage.clear();
          },
          duration: 1
        });
      }
      if (error[0].status === 422) {
        const valErrors = [];
        for (let i = 0; i < error[0].data.length; i++) {
          let message = error[0].data[i].message;
          if (message.includes('Description must be 120 to 160 characters.'))
            message = 'Descrição deve ter entre 120 e 160 caracteres.';
          valErrors.push(message);
        }
        if (validationErrors === undefined) {
          setValidationErrors(valErrors + '\n');
        } else {
          setValidationErrors(validationErrors + valErrors + '\n');
        }
      }
      if (error[0].status === 11000) {
        let err;
        if (error[0].message.includes('duplicate key error collection') === true) {
          err = 'Título ou Descrição duplicados.';
        }
        else {
          err = error[0].message;
        }

        if (validationErrors === undefined) {
          setValidationErrors(err + '\n');
        } else {
          setValidationErrors(validationErrors + err + '\n');
        }
      }
      if (error[0].status === 404) {
        if (id !== 'new') {
          let err;
          if (error[0].message.includes('No product found!') === true) {
            err = 'Produto não encontrado.';
          }
          else {
            err = error[0].message;
          }

          if (validationErrors === undefined) {
            setValidationErrors(err + '\n');
          } else {
            setValidationErrors(validationErrors + err + '\n');
          }
        }
      }
      if (error[0].status === 500) {
        if (id !== 'new') {
          if (error[0].message.includes('Cast to ObjectId failed for value ') === true) {
            notification.open({
              message: 'Produto não encontrado',
              description:
                'Você foi direcionado para a página de Produtos',
              icon: <BarcodeOutlined style={{ color: '#B22222' }} />,
              onClick: () => {
                history.replace('/products/');
              },
              duration: 3
            });
            history.replace('/products/');
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  let draggerUpload = null;
  if (imageList.length < 12) {
    draggerUpload = (
      <Dragger {...propsUploadImages}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
      </Dragger>
    );
  }

  let image = null;
  if (id !== 'new') {
    image = (
      <div>
        <div style={{ textAlign: 'center' }}>
          <h2>Imagem principal</h2>
          <Row gutter={16}>
            <Col span={8}></Col>
            <Col span={8}>
              <Card
                hoverable
                cover={<img title={title} alt={title} src={mainImage} />}
              >
                <Dragger {...propsUploadImage}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                </Dragger>
              </Card>
            </Col>
            <Col span={8}></Col>
          </Row>
          <h2>Imagens secundárias ({imageList.length} de 12)</h2>
          {draggerUpload}
        </div>
        <div className="site-card-wrapper">
          <Row gutter={16}>
            {imageList.map(image => (
              <Col key={image} span={8}>
                <Card onClick={() => selectToDelete(image)} style={{ marginTop: '15px' }}
                  hoverable
                  cover={<img title={title} alt={title} src={image} />}
                >
                  <p style={{ textAlign: 'center' }}>
                    <DeleteFilled />
                  </p>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
        <Modal
          title="Exclusão de registro(s)"
          visible={visibleDelete}
          onOk={deleteData}
          onCancel={deleteCancel}
          okText='Sim'
          cancelText='Não'
        >
          <p>Confirma a exclusão do(s) registro(s) selecionado(s)?</p>
        </Modal>
      </div>
    );
  }

  let showCategories = null;
  if (id !== 'new') {
    showCategories = (
      <Form.Item label="Categorias">
        <Transfer
          disabled={isLoading}
          dataSource={categories}
          listStyle={{
            height: 400,
          }}
          showSearch
          filterOption={filterOption}
          targetKeys={targetCategories}
          onChange={handleCategoriesChange}
          render={item => item.title}
        />
      </Form.Item>
    );
  }

  let showKeywords = null;
  if (id !== 'new') {
    showKeywords = (
      <Form.Item label="Palavras-chave">
        <Transfer
          disabled={isLoading}
          dataSource={keywords}
          listStyle={{
            height: 400,
          }}
          showSearch
          filterOption={filterOption}
          targetKeys={targetKeywords}
          onChange={handleKeywordsChange}
          render={item => item.title}
        />
      </Form.Item>
    );
  }

  return (
    <Layout>
      <PageHeader
        ghost={false}
        onBack={() => history.replace('/products/')}
        title="Produtos"
        subTitle={title === null ? 'Novo registro' : title}></PageHeader>
      <Content style={{ marginTop: '25px', marginLeft: '20px', marginRight: '20px' }}>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Informações gerais" key="1">
            <Form {...formItemLayout} onFinish={handleSubmit} ref={formRef}>
              <Form.Item label="Título" name="title"
                rules={[{
                  required: true,
                  message: 'Favor preencher o Título!'
                }]}>
                <Input />
                {/* <Input disabled={id === 'new' ? false : true} /> */}
              </Form.Item>
              <Form.Item label="Descrição" name="description"
                rules={[{
                  required: true,
                  message: 'Favor preencher a Descrição!'
                }]}>
                <Input />
              </Form.Item>
              <Form.Item label="Detalhes" name="details"
                rules={[{
                  required: true,
                  message: 'Favor preencher os Detalhes!'
                }]}>
                <TextArea rows={5} />
              </Form.Item>
              <Form.Item label="Preço" name="price"
                rules={[{
                  required: true,
                  message: 'Favor preencher o Preço!'
                }]}>
                <InputNumber />
              </Form.Item>
              <Form.Item label="Ativado">
                <Switch checked={isActivated} onChange={onIsActivatedChange} />
              </Form.Item>
              <Form.Item label="Destaque">
                <Switch checked={isFeatured} onChange={onIsFeaturedChange} />
              </Form.Item>
              {
                validationErrors !== '' ?
                  <Form.Item {...tailFormItemLayout}>
                    <Alert
                      message="Verifique o(s) seguinte(s) erro(s)"
                      description={validationErrors}
                      type="error"
                      showIcon
                    />
                  </Form.Item> : null
              }
              <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  Salvar
                            </Button>
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane tab="Imagens" key="2" disabled={id === 'new' ? true : false}>
            <Form {...formItemLayout}>
              {image}
            </Form>
          </TabPane>
          <TabPane tab="Categorias" key="3" disabled={id === 'new' ? true : false}>
            <Form {...formItemLayout}>
              {showCategories}
            </Form>
          </TabPane>
          <TabPane tab="Palavras-chave" key="4" disabled={id === 'new' ? true : false}>
            <Form {...formItemLayout}>
              {showKeywords}
            </Form>
          </TabPane>
        </Tabs>
      </Content>
      <Footer />
    </Layout >
  );
}

export default Product;