import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { fetchKeywords, fetchKeywordsSuccess, deleteKeyword } from '../../store/actions/keywordAction';
import { Link, useHistory } from 'react-router-dom';
import { PageHeader, Layout, Table, Button, notification, Input, Modal } from 'antd';
import { CheckOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

import Footer from '../../components/Footer/Footer';

const { Content } = Layout;

const Keywords = () => {

  const { keywords, isLoading, deleted, error } = useSelector(state => state.keywords);
  const dispatch = useDispatch();

  const [pagination, setPagination] = useState({});
  const [searchText, setSearchText] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [getData, setData] = useState([]);
  const [modalErrorVisible, setModalErrorVisible] = useState(false);
  const [modalErrorTitle, setModalErrorTitle] = useState('');
  const [modalErrorMessage, setModalErrorMessage] = useState('');
  const [modalErrorOkText, setModalErrorOkText] = useState('');

  const history = useHistory();

  const modalDelete = () => {
    setVisibleDelete(true)
  };

  useEffect(() => {
    if (deleted === true) {
      notification.open({
        message: 'Registro excluído com sucesso!',
        icon: <CheckOutlined style={{ color: '#008000' }} />,
        duration: 2
      });
      setTimeout(() => {
        setSelectedRowKeys([]);
      }, 500);
    }
  }, [deleted]);

  const deleteData = e => {
    setVisibleDelete(false);
    for (let i = 0; i < selectedRowKeys.length; i++) {
      dispatch(deleteKeyword(selectedRowKeys[i]));
    }
    if (selectedRowKeys.length > 0) {
      fetch();
    }
  };

  const deleteCancel = e => {
    setVisibleDelete(false);
  };

  const changeError = e => {

    switch (modalErrorOkText) {
      case 'Realizar login como Administrador':
        history.replace('/');
        localStorage.clear();
        break;
      default:
        setTimeout(() => {
          setPagination({});
          setSearchText('');
          setSelectedRowKeys([]);
          setData([]);
          fetch();
        }, 500);
    }
    setModalErrorVisible(false);
  };

  const changeErrorCancel = e => {
    setModalErrorVisible(false);
  };

  const onSelectToDelete = selectedRowKeys => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder='Pesquisar'
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 208, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 110, marginRight: 8 }}
        >
          Pesquisar
            </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reiniciar
            </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const fetch = useCallback((params = {}) => {
    let sortField = params.sortField;
    if (sortField === undefined)
      sortField = 'updatedAt';
    let sortOrder = params.sortOrder;
    if (sortOrder === undefined)
      sortOrder = 'DESC';
    else if (sortOrder === 'ascend')
      sortOrder = 'ASC';
    else if (sortOrder === 'descend')
      sortOrder = 'DESC';
    dispatch(fetchKeywords())
      .then(() => dispatch(fetchKeywordsSuccess(
        searchText, sortField, sortOrder, params.page, params.results
      )));
  }, [searchText, dispatch]);

  useEffect(() => {
    const _pagination = { ...pagination };
    _pagination.total = keywords.totalRecords;
    setPagination(_pagination);
    if (keywords.keywords) {
      setData(keywords.keywords.map(item => {
        return {
          ...item,
          createdAt: new Date(item.createdAt).toLocaleDateString('pt-BR'),
          updatedAt: new Date(item.updatedAt).toLocaleDateString('pt-BR')
        }
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywords.keywords]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    if (error) {
      try {
        if (error[0].status) {
          if (error[0].status === 404 || error[0].status === 401) {
            history.replace('/');
            localStorage.clear();
            setModalErrorVisible(true);
            setModalErrorTitle('Não Autorizado!');
            setModalErrorMessage('Você não tem autorização para acessar esta área.');
            setModalErrorOkText('Realizar login como Administrador');
          }
          else {
            setModalErrorVisible(true);
            setModalErrorTitle('Erro');
            setModalErrorMessage(error[0].message);
            setModalErrorOkText('Ok');
          }
        }
      }
      catch {
        setModalErrorVisible(true);
        setModalErrorTitle('Erro');
        setModalErrorMessage('Erro ao acessar servidor, clique em OK para tentar novamente.');
        setModalErrorOkText('Ok');
      }
    }
  }, [error, history]);

  const handleTableChange = (_pagination, filters, sorter) => {
    const pager = { pagination };
    pager.current = _pagination.current;
    setPagination(pager);
    if (!filters.title) {
      fetch({
        results: _pagination.pageSize,
        page: _pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters
      });
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectToDelete,
    hideDefaultSelections: true
  };
  const hasSelected = selectedRowKeys.length > 0;
  const columns = [
    {
      title: 'Ações',
      dataIndex: 'id',
      key: 'id',
      render: text => <Link to={'/keywords/' + text} key={text}>Editar</Link>,
      fixed: 'left',
      width: 80
    },
    {
      title: 'Título',
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      ...getColumnSearchProps('title'),
    },
    {
      title: 'Criação',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      width: 140
    },
    {
      title: 'Alteração',
      dataIndex: 'updatedAt',
      defaultSortOrder: 'descend',
      key: 'updatedAt',
      sorter: true,
      width: 140
    }
  ];

  return (
    <Layout>
      <PageHeader title="Palavras-chave"></PageHeader>
      {/* {keys ?
        <div>
          <ul>
            {keys.keywords.map(k => (
              <li key={k.id}>{k.title}</li>
            ))}
          </ul>
        </div> :
        <ul>
          <li>A</li>
          <li>B</li>
        </ul>
      } */}
      <Content style={{ marginTop: '25px', marginLeft: '20px', marginRight: '20px' }}>
        <div>
          <div style={{ marginBottom: 16 }}>
            <Button type="primary" onClick={modalDelete} disabled={!hasSelected} loading={isLoading}>
              Excluir
                            </Button>
            <Modal
              title="Exclusão de registro(s)"
              visible={visibleDelete}
              onOk={deleteData}
              onCancel={deleteCancel}
              okText='Sim'
              cancelText='Não'
            >
              <p>Confirma a exclusão do(s) registro(s) selecionado(s)?</p>
            </Modal>
            <Modal
              title={modalErrorTitle}
              visible={modalErrorVisible}
              onOk={changeError}
              onCancel={changeErrorCancel}
              okText={modalErrorOkText}
            >
              <p>{modalErrorMessage}</p>
            </Modal>
            <span style={{ marginLeft: 8 }}>
              {hasSelected ? `${selectedRowKeys.length} registro(s) selecionado(s)` : ''}
            </span>
          </div>
          <Table rowSelection={rowSelection} columns={columns} dataSource={getData} pagination={pagination} rowKey={record => record.id} loading={isLoading} onChange={handleTableChange} scroll={{ x: 1300 }} />
          <div style={{ marginTop: 16 }}>
            <Button type="primary" onClick={() => history.replace('/keywords/new')}>
              Novo registro
            </Button>
          </div>
        </div>
      </Content>
      <Footer />
    </Layout>
  );
};

export default Keywords;